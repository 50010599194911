module controllers {
    export module tariff {
        interface IProhibitedAndRestrictedUpdateParams extends ng.ui.IStateParamsService {
            parId: number;
        }

        export class prohibitedAndRestrictedUpdateCtrl {

            static $inject = [
                '$scope',
                '$rootScope',
                '$q',
                'generalService',
                'bsLoadingOverlayService',
                '$anchorScroll',
                '$state',
                'prohibitedAndRestrictedService',
                '$stateParams',
                '$uibModal',
                'menuService',
                'documentRepositoryService',
            ];

            apiTariffCodes: uiGrid.IGridApi;
            TariffCodes: Array<interfaces.tariff.ITariffCodeDisplay> = [];
            permit: interfaces.tariff.IProhibitedAndRestrictedDetail;
            parId: number;
            showGrid: boolean = true;
            summaryOpen: boolean = false;
            documentRepository: interfaces.documentManagement.IDocumentRepository;
            showAll: boolean = false;
            
            constructor(
                private $scope: ng.IScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private bsLoadingOverlayService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
                private $stateParams: IProhibitedAndRestrictedUpdateParams,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private menuService: interfaces.applicationcore.IMenuService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService
            ) {
                if ($stateParams.parId === null) {
                    return;
                }

                this.parId = $stateParams.parId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadProhibitedAndRestrictedDetail());
                //loadPromises.push(this.getDocuments());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prohib.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadProhibitedAndRestrictedDetail(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prohib.update'
                },
                    () => {
                        this.prohibitedAndRestrictedService.getDetail().get({
                            parId: this.parId
                        }, (result: interfaces.tariff.IProhibitedAndRestrictedDetail) => {
                            this.gvwTariffCodes.data = result.TariffCodes;
                            this.permit = result;
                            this.loadControls();
                            deferre.resolve(true);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            deferre.resolve(false);
                        });
                    });

                return deferre.promise;
            }

            
            loadControls() {
                var promises = [];
     
                promises.push(this.getDocuments(this.showAll));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.$q.all(promises);
                });
            }


            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.permit.LawCode, systemType: Enum.EnumSystemType.Law, ownerEntityId: 0, showAll:showAll  }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }


            gvwTariffCodes: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: false,
                onRegisterApi: (gridApi) => { this.registerTariffCodesGridApi(gridApi); },
                columnDefs: [{
                    name: 'TariffCode',
                    displayName: 'Tariff Code',
                    field: 'TariffCode',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 150
                }, {
                    name: 'Exceptions',
                    displayName: 'Exceptions',
                    field: 'Exceptions',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 150
                }, {
                    name: 'Notes',
                    displayName: 'Notes',
                    field: 'Notes',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 500
                }]
            };
            
            registerTariffCodesGridApi(gridApi: uiGrid.IGridApi) {
                this.apiTariffCodes = gridApi;
                this.apiTariffCodes.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiTariffCodes.selection.selectRow(gridSelection.row.entity);
                });
            }

            openFullText(fullText: string, title: string)
            {
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="fullText">
                                <div class="gts-padding">
                                    <fieldset id="fullText">
                                    <legend>
                                       {{Title}}
                                    </legend>
                                    <p ng-bind-html="FullText"></p>                                  
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.FullText = fullText;
                        $scope.Title = title;

                        $scope.ok = () => {
                            $uibModalInstance.close();
                        }
                    },
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            downloadForm(formId: number) {
                this.prohibitedAndRestrictedService.downloadForm(formId);
            }
        };

        angular.module("app").controller("prohibitedAndRestrictedUpdateCtrl", controllers.tariff.prohibitedAndRestrictedUpdateCtrl);
    }
}